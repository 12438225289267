<template>
  <div>
    <title>SIM INVENTORY ~ EDIT GENERAL JOURNAL LIST DETAILS</title>
    <section class="content-header">
      <h1>
        Edit General Journal List Details
        <br />
        <h4>
          Please update the transaction data for the detailed general journal
          list
        </h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Transaction List General Journal Detail</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <!-- left column -->
        <div class="col-md-6">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-edit"></i>
              <h3 class="box-title">Update General Journal Details Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Number of evidence</label>
                  <input
                    type="text"
                    v-model="nobuktijud"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Number of evidence"
                  />
                  <input
                    type="hidden"
                    v-model="idxjud"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Description</label>
                  <input
                    type="text"
                    v-model="keterangan"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Description"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-6">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-edit"></i>
              <h3 class="box-title">Update General Journal Details Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">COA</label>
                  <select2
                    :data="coalist"
                    :value="valuecoa"
                    @update="updatecoa($event)"
                  ></select2>
                </div>

                <!--<div class="row">
                  <div class="col-xs-9">
                    <label for="exampleInputEmail1"> COA Code</label>
                    <input
                      type="text"
                      v-model="coakode"
                      autocomplete="off"
                      class="form-control"
                      placeholder="COA Code"
                    />
                  </div>
                  <div class="col-xs-3">
                    <label for="exampleInputEmail1">Action</label>
                    <br />
                    <button
                      type="button"
                      @click="getcoakode()"
                      class="btn btn-info"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <br /> -->
                <div class="form-group">
                  <label for="exampleInputEmail1">Reference</label>
                  <input
                    type="text"
                    v-model="refjud"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Reference"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Debit</label>
                  <input
                    type="number"
                    v-model="debetjud"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Debit"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Credit</label>
                  <input
                    type="number"
                    v-model="kreditjud"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Credit"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Update
                </button>
                &nbsp;
                <router-link to="/jurnalumum">
                  <button class="btn btn-success">
                    <i class="fa fa-angle-double-left"></i> Back
                  </button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addprosesdprod",
  data() {
    return {
      loading: false,
      nobuktijud: "",
      idxjud: "",
      notesjud: "",
      // coakode: "",
      refjud: "",
      debetjud: "",
      kreditjud: "",
      keterangan: "",
      valuecoa: "",
      coalist: []
    };
  },
  created() {
    // this.loadData();
    this.getCOAList();
  },
  methods: {
    getCOAList() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/supplier/getalldatasupplier?length="+this.pageSize+"&page="+this.page;
      const urlAPIget = this.$apiurl + "coa/getalldatacoa?length=5000&page=1";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          this.coalist = resp.data.data;
          this.coalist.forEach((item) => {
            item.value = item.coa_code;
            item.label = item.coa_name;
          });
          this.loading = false;
          this.loadData()
        })
        .catch((err) => {
          // console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    updatecoa(value) {
      this.valuecoa = value;
    },
    loadData() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/jurnal_umum_d/getjurnal_umum_dbyid?id=" + this.$route.params.id;
      const urlAPIget = this.$apiurl + "jurnal_umum_d/getjurnal_umum_dbyid?id=" + this.$route.params.id;

      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          // console.log(resp)
          this.nobuktijud = resp.data.data.no_bukti;
          this.idxjud = resp.data.data.id;
          this.valuecoa = resp.data.data.coa_code;
          this.refjud = resp.data.data.referensi;
          this.debetjud = resp.data.data.debet;
          this.kreditjud = resp.data.data.kredit;
          this.keterangan = resp.data.data.notes;
          this.loading = false
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    async updateData() {
      this.loading = true;
      var idx = this.idxjud;
      var nobuk = this.nobuktijud;
      var referensi = this.refjud;
      var valcoa = this.valuecoa;
      if (referensi == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Reference can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (nobuk == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Number of evidence can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (valcoa == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "COA can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdatadetail = {
          no_bukti: this.nobuktijud,
          coa_code: this.valuecoa,
          notes: this.keterangan,
          kode_user: kodeuser,
          referensi: this.refjud,
          debet: this.debetjud,
          kredit: this.kreditjud
        };

        //console.log(paramdatadetail);
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        // const urlAPIUpdateDatadetail = "http://26.183.23.191/inventory/backend/inventory/api/jurnal_umum_d/updatejurnal_umum_d/" + idx;
        const urlAPIUpdateDatadetail =
          this.$apiurl + "jurnal_umum_d/updatejurnal_umum_d/" + idx;
        axios
          .put(urlAPIUpdateDatadetail, paramdatadetail, { headers: headers })
          .then((respn) => {
            if (respn.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
